<template>

	<div class="d-flex justify-space-between line-bottom my-3">
		<div>
			<v-btn icon @click="goUrl('https://twitter.com/aciesguru','_blank')">
				<v-icon color="grey lighten-2">mdi-twitter</v-icon>
			</v-btn>
			<v-btn icon @click="goUrl('https://www.linkedin.com/company/aciesguru/','_blank')">
				<v-icon color="grey lighten-2">mdi-linkedin</v-icon>
			</v-btn>
		</div>
		<div>
			<v-btn text class="text-lowercase text-right" @click="goUrl('mailto:contact@acies.guru','_blank')">
				<v-icon color="grey darken-2">mdi-mail</v-icon> <span class="mr-20">contact@acies.guru</span>
			</v-btn>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Toolbar',
		methods: {
			goUrl(url,target){
				window.open(url, target)
			}
		}
	}
</script>
<style scoped>
	.line-bottom {
    border-bottom: 1px solid #EEEEEE !important
}
</style>

