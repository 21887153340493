<template>
	<v-container fluid  fill-height >
		<v-row justify="center" align-content="center" align="center">
			<v-col cols="12" >
				<v-img src="@/assets/logo.png" width="250" contain class="mx-auto" />
				<h1 class="text-center">TEST DEBUG VIEW</h1>
			</v-col>
			<v-col>
				<v-card
					class="mx-auto"
					max-width="600"
				>
    <v-card-title class="title font-weight-regular justify-space-between">
      <span>{{ currentTitle }}</span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="24"
        v-text="step"
      ></v-avatar>
    </v-card-title>

    <v-window v-model="step">
      <v-window-item :value="1">
        <v-card-text>
					<v-form
						ref="form"
						v-model="valid"
						lazy-validation
					>
						<v-text-field
							v-model="PDNo"
							:rules="[rules.required]"
							label="PO #"
							required
						/>
						<v-text-field
							v-model="product"
							:rules="[rules.required]"
							label="Product"
							required
						/>
						<v-text-field
							v-model="provider"
							:rules="[rules.required]"
							label="provider"
							required
						/>
						<v-text-field
							v-model="taxamount"
							:rules="[rules.required]"
							label="Tax amount"
							required
						/>
						<v-text-field
							v-model="tc"
							:rules="[rules.required]"
							label="Tax code"
							persistent-hint
							hint="tc## (case-sensitive)"
							required
							@change="toLowerCase"
						/>
					</v-form>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2">
				<v-alert
					class="my-4"
					prominent
					type="error"
					v-if="tc != result[0].displayName"
				>
					<strong>"TC ({{tc}}) given is likely to be incorrect!"</strong><br/>
					We estimate the correct value to be <strong>{{result[0].displayName}}</strong>: <strong>{{result[0].score}}%</strong>.	
			</v-alert>
			<v-alert type="success" v-else>
				This value is correct Tax code <strong>{{tc}}</strong>: <strong>{{result[0].score}}</strong>%.
			</v-alert>

				
        <v-card-text>
					<h3>Confidence score (%)</h3>
					<v-list-item two-line v-for="(item,index) in result" :key="index">
						<v-list-item-content class="gray">
							<v-list-item-title><strong>{{ item.displayName}}</strong></v-list-item-title>
							<p>Score: {{item.resScore}}</p>
							<p>Baseline: {{item.resBase}}</p>
	
								<div class="d-flex align-center">
								<div class="mr-4"><strong> {{ item.score}}</strong></div>
									<v-progress-linear
										color="primary"
										height="30"
										:value="item.score"
										striped
									></v-progress-linear>

								</div>

							<!-- <div class="my-2" v-for="(res, idx) in item.tables" :key="idx">
										<div>Importance: {{ res.importance}}</div>
										<div>Display Name: {{ res.displayName}}</div>
							</div> -->
							<v-divider/>
						</v-list-item-content>
					</v-list-item>
        </v-card-text>
				
      </v-window-item>

    </v-window>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="step === 1"
        color="primary"
        depressed
        @click="goInit()"
      >
        Form Input
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="step === 2"
        color="primary"
        depressed
        @click="goPredict()"
				:loading="loading"
      >
        Predict
      </v-btn>
    </v-card-actions>
  </v-card>
			</v-col>
			<v-col cols="12">

							<pre v-if="predict != {} ">
								{{ predict }}
							</pre>



			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import axios from 'axios';
  export default {
		name: 'Home',
    data: () => ({
			valid: false,
			tc: '',
			linea: 20,
			predict: {},
			loading: false,
			step: 1,
			PDNo: '5000008929',
			product: 'Waste Transportation / Disposal',
			provider: 'VEOLIA ENVIRONMENTAL SERVICES',
			taxamount: '1872',
			rules: {
				required: value => !!value || 'Required.',
			},
			result: [{
				id: 0,
				score: null,
				displayName: null,
				tables: [{
					importance: null,
					displayName: null
				}]
			}]	

		}),
		methods: {
			async goPredict(){

				let res = this.$refs.form.validate();

				if (res) {

					let me = this;
					me.loading = true;
					let data = {
						PDNo: me.PDNo,
						product: me.product,
						provider: me.provider,
						taxamount: me.taxamount
					}
					await axios.post('predict',data)
						.then(
							function (response) {

								me.predict = response.data;
								me.result = [];
								let index = 1;

								for (const result of response.data) {
									// console.log(result);
									// console.log(`Predicted class name: ${result.displayName}`);
									// console.log(`Predicted class score: ${result.tables.score}`);
									
									// Get features of top importance
									const featureList = result.tables.tablesModelColumnInfo.map(
										columnInfo => {
											return {
												importance: columnInfo.featureImportance,
												displayName: columnInfo.columnDisplayName,
											};
										}
									);
									// Sort features by their importance, highest importance first
									featureList.sort((a, b) => {
										return b.importance - a.importance;
									});

									// Print top 10 important features
									//console.log('Features of top importance');
									//console.log(featureList.slice(0, 10));

									me.result.push({
										id: index,
										displayName: result.tables.value.stringValue,
										//score: ( (result.tables.score + result.tables.baselineScore) * 100 ).toFixed(2),
										score: ( (result.tables.score) * 100 ).toFixed(2),
										tables: featureList.slice(0, 10),
										resScore: result.tables.score,
										resBase: result.tables.baselineScore
									});
									index++;

								}

								me.result.sort((a, b) => {
									return b.score - a.score;
								});

								me.step = 2;
								me.loading = false;
							})
						.catch(function (e) {
							me.loading = false;
							console.log(e.message)
						})

				} else {
					this.loading = false;
				}


			},
			goInit() {
				this.preedict = {},
				this.step = 1;
				this.tc = ''
				this.$refs.form.resetValidation()
			},
			toLowerCase (obj ){
				this.tc = obj.toString().toLowerCase();
			},

		},
    computed: {
      currentTitle () {
        switch (this.step) {
          case 1: return 'Input data form'
          case 2: return 'Prediction results: Tax code '
          default: return 'Acies Predict'
        }
      },
    },
  }
</script>
<style  scoped>
	.lowecase input{
  text-transform: lowecase
}
</style>