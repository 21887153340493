<template>
	<v-main>
		<slot />
	</v-main>
</template>

<script>
	export default {
		
	}
</script>

<style lang="scss" scoped>

</style>