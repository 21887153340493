import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import DefaultLayout from './layouts/Default.vue';


axios.defaults.baseURL = 'https://acies-guru.herokuapp.com/';
//axios.defaults.baseURL = 'http://localhost:3000';



Vue.component('Layout', DefaultLayout);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
