<template>
	<header class="d-flex flex-column flex-sm-row my-4">
		<div class="mx-auto sm:mr-auto">
			<v-img @click="goUrl('https://acies.guru','')" src="@/assets/logo.png" width="250" contain class="mx-auto" />
		</div>
		<div class="d-flex align-center pa-2 font-weight-black mx-auto sm:mr-auto">
			<a class="link-header mr-4" href="https://acies.guru/">HOME</a>
			<a class="link-header mr-4" href="https://acies.guru/about/" >ABOUT US</a>
			<a class="link-header mr-4" href="https://acies.guru/predictive-analytics-contact/" >CONTACT</a>
		</div>
	</header>
</template>

<script>
	export default {
		name: 'Header',
		methods: {
			goUrl(url,target){
				window.open(url, target)
			}
		}
	}
</script>

<style scoped>
	.link-header {
		text-decoration: none;
	}
</style>